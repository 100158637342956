import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Calendar from "../components/Calendar";
import TestimonialCarousel from "../components/Carousel";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const debounceHome = function(func, wait) {
  let timeout
  return function(...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

const coursesArray = [
{
  title: "Sobrecargo de Aviación",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "3",
  month: "Febrero"
},
{
  title: "Oficial de Operaciones Aeronáuticas",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "3",
  month: "Febrero"
},
{
  title: "Técnico en Mantenimiento Clase 1",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "4",
  month: "Mayo"
},
{
  title: "Meteorólogo Aeronáutico Clase 1",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "4",
  month: "Mayo"
},
{
  title: "Sobrecargo de Aviación",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "1",
  month: "Junio"
},
{
  title: "Oficial de Operaciones Aeronáuticas",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "1",
  month: "Junio"
},
{
  title: "Técnico en Mantenimiento Clase 1",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "3",
  month: "Agosto"
},
{
  title: "Meteorólogo Aeronáutico Clase 1",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "3",
  month: "Agosto"
},
{
  title: "Sobrecargo de Aviación",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "7",
  month: "Septiembre"
},
{
  title: "Oficial de Operaciones Aeronáuticas",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "7",
  month: "Septiembre"
},
{
  title: "Sobrecargo de Aviación",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "7",
  month: "Diciembre"
},
{
  title: "Oficial de Operaciones Aeronáuticas",
  location: "CDMX, GDL, TOLUCA",
  time: "Matutino",
  date: "7",
  month: "Diciembre"
},
];


class Welcome extends Component {
  state = {
    imageCounter: 1,
    windowWidth: 1200,
    windowHeight: 800,
    courses: coursesArray
  };

  componentDidMount() {
    this.galleryInterval = setInterval(this.responsiveAction, 5000);
    this.updateWindowDimensions();
    window.addEventListener('resize', debounceHome(this.updateWindowDimensions, 1000));
  }

  componentWillUnmount() {
    clearInterval(this.galleryInterval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight }, () => {console.log(this.state)});
  }

  responsiveAction = () => {
    if (this.state.windowWidth > 850){
      this.cycleImages()
    }
  }

  cycleImages = () => {
    var currentImage = this.state.imageCounter
    if(currentImage < 4){
      var targetImage = document.getElementById(`gallery-${currentImage}`)
      targetImage.classList.add('hidden')
      targetImage.classList.remove('visible')
      currentImage++
      this.setState(
        {
          imageCounter: currentImage
        }
      );
    }
    else{
      var gallery = document.getElementsByClassName('gallery');
      for(var i = 0; i< gallery.length; i++){
        gallery[i].classList.add('visible');
        gallery[i].classList.remove('hidden');
      }
      this.setState(
        {
          imageCounter: 1
        }
      );
    }
  }

  render() {
    return (
      <div>
      <Nav/>
      <Container className="hero-container" fluid style={{ paddingLeft: 0, paddingRight: 0 }}>

        <img className="header-tornado" src="images/tornadobanner.png" alt="header"></img>

        <h1 className="hero-title text-center page-title-white"> TORNADO AVIATION TRAINING CENTERS </h1>
        <h3 className="hero-subtitle text-center page-text-white"> Decide lo alto que quieres volar </h3>

      </Container>

      <Container className="page-content" fluid="true">

        <Row className="mt-4" noGutters="true">
          <Col md="1"></Col>

          <Col md="10" className="text-center">
            <h2 className="page-title mt-2 mt-md-5"> ¿POR QUÉ NOSOTROS?</h2>
            <p className="page-text mt-4" style={{ fontSize: "1.2rem"}}> Somos una escuela de aviación en México con más de 20 años de experiencia 
            e instructores altamente calificados. Nuestros cursos de capacitación certificados por la AFAC te brindan 
            las herramientas necesarias para iniciar tu carrera en la industria de la aviación. De igual manera 
            ofrecemos servicios de asesoría y tramitación aeronáutica. Contamos con bases en CDMX, Guadalajara y Toluca. </p>
            <Row className="mt-5">
              <Col md="4">
                <img className="section-icon" src="images/piloto.png" alt="carreras"></img>
                <h3 className="page-title mt-4"> Carreras </h3>
                <p className="carreras-text page-text-jus mt-4 px-3"> Formamos Personal Técnico Aeronáutico para las carreras de Sobrecargo, 
                Piloto Comercial, Oficial de Operaciones, Técnico en Mantenimiento, Obervador del Tiempo, entre otros.
                </p>
                <a href="/cursos/carreras"><button className="btn btn-mobile btn-tornado mt-3"> Más información </button></a>
              </Col>
              <Col md="4">
                <img className="section-icon" src="images/simulador.png" alt="simuladores"></img>
                <h3 className="page-title mt-4"> Simuladores </h3>
                <p className="page-text-jus mt-4 px-3"> Única Escuela de Aviación en México que cuenta con un avión McDonnell 
                Douglas DC-9-32 de aerolínea para realizar las prácticas de simulación de emergencias (Ditching).
                </p>
                <a href="/nosotros#emersim"><button className="btn btn-mobile btn-tornado mt-3"> Más información </button></a>
              </Col>
              <Col md="4">
                <img className="section-icon" src="images/dron.png" alt="drones"></img>
                <h3 className="page-title mt-4"> Drones </h3>
                <p className="page-text-jus mt-4 px-3"> Somos pioneros en brindar Cursos de Capacitación Piloto Remoto (Drone). 
                Obtén con nosotros la licencia de piloto RPAS avalada por la SCT y la AFAC.
                </p>
                <a href="/drones"><button className="btn btn-mobile btn-tornado mt-3"> Más información </button></a>
              </Col>
            </Row>
            <Row className="desktop-buttons">
              <Col md="4"><a href="/cursos/carreras"><button className="btn btn-tornado mt-3"> Más información </button></a></Col>
              <Col md="4"><a href="/nosotros#emersim"><button className="btn btn-tornado mt-3"> Más información </button></a></Col>
              <Col md="4"><a href="/drones"><button className="btn btn-tornado mt-3"> Más información </button></a></Col>
            </Row>
          </Col>
          
          <Col md="1"></Col>  
        </Row>

      </Container>

      <div className="testimonial-container">
        <div className="testimonial-div">
          <Container>
            <h2 className="page-title-white-centered mt-5">NUESTROS CLIENTES</h2>
            <TestimonialCarousel carouselPosition={this.state.imageCounter}/>
          </Container>  
        </div>
        <div className="image-cover"></div>
        <img className="testimonial-img gallery visible" src="images/emersim.jpg" id="gallery-1" alt="testimonial"></img>
        <img className="testimonial-background gallery visible" src="images/tornado-3.jpg" id="gallery-2" alt="testimonial"></img>
        <img className="testimonial-background gallery visible" src="images/tornado-4.jpg" id="gallery-3" alt="testimonial"></img>
        <img className="testimonial-background gallery visible" src="images/tornado-5.jpg" id="gallery-4" alt="testimonial"></img>
      </div>

      <Container fluid className="careervideo-container">
        <Row>
          <Col xl="1"></Col>
          <Col className="emersim-details" id="emersim">
            <h2 className="page-title text-center">CONOCE NUESTRAS CARRERAS</h2>
            <p className="page-text sim-text text-center mt-3">
            Tornado tiene los cursos con los mejores planes de estudio para aspirantes a Piloto Privado, Sobrecargo de Aviación, Oficial de Operaciones y más.
            Nosotros te preparamos para que puedas reunir el perfil que las aerolíneas están buscando. Descubre lo que Tornado te ofrece este 2022:
            </p>
          </Col>
          <Col xl="1"></Col>
        </Row>
        <Row>
          <div className="ditching-video-container">
            <iframe title="carreras" width="100%" height="100%" src="https://www.youtube.com/embed/KElqeQr_crQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Row>
{/*         <Row className="text-center mt-5">
          <a className="contact-arrow" href="/cursos/carreras"><h3 className="page-title">MÁS INFORMACIÓN</h3></a>
        </Row> */}
      </Container>

      <Container>
        <h2 className="page-title text-center">NOTICIAS</h2>
        <div className="news-poster">
          <img id="poster-image" src="images/becas.png"></img>
        </div>
      </Container>

{/*       <Calendar
      windowWidth={this.state.windowWidth}
      courses={this.state.courses}
      /> */}

      <Footer/>

      </div>
    );
  }
}

export default Welcome;
