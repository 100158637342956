import React, { Component } from "react";
import Nav from "../components/Nav";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Footer from "../components/Footer";

class Courses extends Component {
  state = {
    
  };

  componentDidMount() {
  }

  render() {
    if (this.props.match.params.type === "carreras"){
      return (
        <div>
        <Nav/>
        <h3 className="page-title text-center mt-5">NUESTRAS CARRERAS</h3>
        <Container className="careers-container">
          <Row>
            <Col md="6">
              <a href="/cursos/carreras/sobrecargo">
                <div className="course-card left mt-4 mb-lg-4">
                  <div className="course-image-div"> 
                    <img className="course-card-img" src="/images/carrera-sobrecargo.jpg" alt="sobrecargo"></img>
                  </div>
                  <div className="course-text-div">
                    <div className="course-text page-title-white text-center px-3 px-md-5"> Sobrecargo de Aviación </div>
                  </div>
                </div>
              </a>
            </Col>
            <Col md="6">
              <a href="/cursos/carreras/oficial-de-operaciones">
                <div className="course-card right mt-4 mb-lg-4">
                  <div className="course-image-div"> 
                    <img className="course-card-img" src="/images/carrera-oficial.jpg" alt="oficial"></img>
                  </div>
                  <div className="course-text-div">
                    <div className="course-text page-title-white text-center px-3 px-md-5"> Oficial de Operaciones Aeronáuticas </div>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <a href="/cursos/carreras/piloto-privado">
                <div className="course-card left mt-4 mb-lg-4">
                  <div className="course-image-div"> 
                    <img className="course-card-img" src="/images/carrera-pilotoprivado.jpg" alt="piloto"></img>
                  </div>
                  <div className="course-text-div">
                    <div className="course-text page-title-white text-center px-3 px-md-5"> Piloto Privado de Ala Fija </div>
                  </div>
                </div>
              </a>
            </Col>
            <Col md="6">
              <a href="/cursos/carreras/piloto-tpi">
                <div className="course-card right mt-4 mb-lg-4">
                  <div className="course-image-div"> 
                    <img className="course-card-img" src="/images/carrera-pilototransporte.jpg" alt="piloto"></img>
                  </div>
                  <div className="course-text-div">
                    <div className="course-text page-title-white text-center px-3 px-md-5"> Piloto de Transporte Público Ilimitado </div>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <a href="/cursos/carreras/observador-del-tiempo">
                <div className="course-card left mt-4 mb-lg-4">
                  <div className="course-image-div"> 
                    <img className="course-card-img" src="/images/carrera-observador.jpg" alt="observador"></img>
                  </div>
                  <div className="course-text-div">
                    <div className="course-text page-title-white text-center px-3 px-md-5"> Observador del Tiempo </div>
                  </div>
                </div>
              </a>
            </Col>
            <Col md="6">
              <a href="/cursos/carreras/tecnico-en-mantenimiento">
                <div className="course-card right mt-4 mb-lg-4">
                  <div className="course-image-div"> 
                    <img className="course-card-img" src="/images/carrera-tecnico.jpg" alt="tecnico"></img>
                  </div>
                  <div className="course-text-div">
                    <div className="course-text page-title-white text-center px-3 px-md-5"> Técnico en Mantenimiento Clase 1 </div>
                  </div>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
        <Footer/>
        </div>
      );
    }
    
    else if (this.props.match.params.type === "capacitacion"){
      return (
        <div>
          <Nav/>
          <h3 className="page-title text-center mt-5">NUESTROS CURSOS DE CAPACITACIÓN</h3>
          <Container className="course-container">
            <Row>
              <Col md="6">
                <div className="course-list-card left mb-4">
                  <div className="container mt-3 course-list-title">
                    <h4 className="page-title-white text-center mt-3 mb-3"> ADIESTRAMIENTO (ALA FIJA) </h4>
                    <ul className="page-text-white">
                      <li>Sabreliner NA-265 Series (Piloto / Mantenimiento)</li>
                      <li>Learjet 20/30 (Piloto / Mantenimiento)</li>
                      <li>Learjet 20/30 (Piloto / Mantenimiento)</li>
                      <li>Learjet 45 (Piloto / Mantenimiento)</li>
                      <li>Learjet 40/45 (normal, XR y ER) (Piloto / Mantenimiento)</li>
                      <li>Learjet 55 (Piloto / Mantenimiento)</li>
                      <li>Learjet 60 (Piloto / Mantenimiento)</li>
                      <li>Learjet 70/75 (Piloto / Mantenimiento)</li>
                      <li>Hawker HS-125 series (Piloto / Mantenimiento)</li>
                      <li>Cessna Citation 650 (III,VI,VII) (Piloto / Mantenimiento)</li>
                      <li>Cessna Citation 500`s (Piloto / Mantenimiento)</li>
                      <li>King air 90 series, 200 series (Piloto)</li>
                      <li>King air 90 series, 200 series, 300 series (Mantenimiento)</li>
                      <li>Beechjet 400 y 400A  (hawker 400xp) (Piloto / Mantenimiento)</li>
                      <li>Caravan 208 (Piloto / Mantenimiento)</li>
                      <li>Pilatus PC-12 (series) (Piloto / Mantenimiento)</li>
                      <li>TURBOCOMMANDER series (Piloto / Mantenimiento)</li>
                      <li>FALCON 2000s (Piloto / Mantenimiento)</li>
                      <li>CHALLENGER 600s (Piloto / Mantenimiento)</li>
                      <li>Gulstream G200 (Piloto / Mantenimiento)</li>
                      <li>Fairchild Merlin II/III, Metroliner SA266 (Mantenimiento)</li>
                      <li>Convair 240/340/580/600 (Piloto)</li>
                      <li>Convair 240/580/600 (Mantenimiento)</li>
                      <li>Cessna 100´s (Mantenimiento)</li>
                      <li>Cessna 200´s (Mantenimiento)</li>
                      <li>Cessna 400´s (Mantenimiento)</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="course-list-card right mb-4">
                  <div className="container mt-3 course-list-title">
                    <h4 className="page-title-white text-center mt-md-3 mb-3"> ADIESTRAMIENTO (ALA ROTATIVA) </h4>
                    <ul className="page-text-white">
                      <li>Bell 206 Series B, LI, LIII, LIV (Piloto)</li>
                      <li>Bell 206 (Mantenimiento)</li>
                      <li>Bell 407 (Piloto / Mantenimiento)</li>
                      <li>Bell 412/EP (Mantenimiento)</li>
                      <li>Bell 212 (Mantenimiento)</li>
                    </ul>
                    <h4 className="page-title-white text-center mt-5 mt-md-3 mb-3"> CURSOS COMPLEMENTARIOS </h4>
                    <ul className="page-text-white">
                      <li>CRM  (Crew  Resources Management)</li>
                      <li>SMS  (Safety Management System)</li>
                      <li>RVSM  (Reduced Vertical Separation minimum)</li>
                      <li>ALAR  (Approach and Landing Accident Reduction)</li>
                      <li>CFIT  (Controlled Flight Into Terrain)</li>
                      <li>Seguridad Aérea  (seguridad operacional)</li>
                      <li>Manejo de Mercancías Peligrosas</li>
                      <li>Ditching  (Evacuación de emergencia)</li>
                      <li>TRM (Total Resources Management)</li>
                      <li>Operaciones en climas fríos</li>
                      <li>Calidad en la aviación</li>
                      <li>PBN (Performance Based Navigation)</li>
                      <li>Formación de instructores</li>
                      <li>Rampa y combustible</li>
                      <li>DRM (Dispatch Resources Management)</li>
                      <li>Factores Humanos en la aviacion</li>
                      <li>MRM (Maintenance Resources Management)</li>
                      <li>Radiotelefonista aeronáutico restringido</li>
                      <li>Reglamentación Aeronáutica</li>
                      <li>Primeros Auxilios</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-5 mb-5">
              <div className="contact-arrow text-right"><a className="page-title" href="/contacto"> Informes de inscripción ➔ </a></div>
            </Row>
          </Container>
          <Footer/>
        </div>
      );
    }
  }
}


export default Courses;
