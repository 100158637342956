import React, { Component } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer"
import InfoHeader from "../components/InfoHeader";

class Consulting extends Component {

  state = {
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <Nav />
        <InfoHeader page="asesoria"/>
        <Footer />
      </div>
    );
  }
}

export default Consulting;