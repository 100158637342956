import React, { Component } from "react";
import "./style.css";
import {Navbar, Nav} from 'react-bootstrap'

const debounceNav = function(func, wait) {
  let timeout
  return function(...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

class Navigation extends Component {
  state = {
    navWidth: 1200,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', debounceNav(this.updateWindowDimensions, 1000));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ navWidth: window.innerWidth}, () => {console.log(this.state)});
  }

  render() {
    return (
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <img className="logo-img" src="/images/logotornado.png" alt="logo"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link href="/nosotros">Nosotros <span className="sr-only">(current)</span></Nav.Link>
            <Nav.Link href="/cursos/carreras">Carreras</Nav.Link>
            <Nav.Link href="/cursos/capacitacion">Cursos</Nav.Link>
            <Nav.Link href="/drones">Drones</Nav.Link>
            <Nav.Link href="/asesoria">{this.state.navWidth <= 1300 ? "Asesoría" : "Asesoría y Gestoría"}</Nav.Link>
            <Nav.Link href="/sms">SMS</Nav.Link>
            <Nav.Link href="/contacto">Contacto</Nav.Link> 
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
