import React from "react";
import "./style.css";

function Certifications(props) {
	return (
		<div>
			<div className="cert-title-mobile-div">
				<h2 className="cert-title page-title-white-centered">CERTIFICACIONES</h2>
			</div>
			<div className="certifications">
				<div className="first-cert">
					<p className="page-text dgac-text">Permiso de escuela de aviación AFAC L-43 emitida por la Secretaria de Comunicaciones 
					y Transportes, mediante la Agencia Federal de Aviación Civil.</p>
					<img className="logo-dgac" src="/images/afac.jpg" alt="logo-afac"></img>
				</div>
				<div className="separator"></div>
				<div className="second-cert">
					<p className="page-text stps-text">Reconocimiento de la Secretaria del Trabajo y Previsión Social, 
					como "Agente capacitador externo".</p>
					<img className="logo-stps" src="/images/stps.png" alt="logo-stps"></img>
				</div>
				<div className="cert-cover">
					<h3 className="cert-title page-title-white-centered">CERTIFICACIONES</h3>
				</div>
			</div>
		</div>
	);
}

export default Certifications;