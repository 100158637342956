import React from "react";
import "./style.css";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Calendar(props) {
  return (
      <Container className="calendar">
        <Row>
          <Col md="4" className="mb-5 mb-md-0">
            <div className="title-banner">
              <h2 className="page-title calendar-title text-center mx--lg-5"> PRÓXIMAS FECHAS </h2>
              <div className="cursos-banner page-text-white text-center mt-4"> CURSOS 2020 </div>
              <img className="small-plane mt-5" src="images/smallplane.png" alt="smallplane"></img>
            </div>
          </Col>
          <Col md="8" className="mt-3 mt-md-0">
            {props.courses.map(course => (
              <Row className="calendar-row mt-3">
                <div className="ml-lg-5 event-details">
                  <h6 className="event-title mt-md-4 mt-2 ml-lg-5 ml-3"> {course.title.toUpperCase()} </h6>
                  <ul className="page-text ml-md-5 ml-2">
                    <li> {course.location} </li>
                    <li> {course.time} </li>
                  </ul>
                </div>
                <div className="ml-xl-4 ml-3 date-banner">
                  <div className="date-number"> {course.date} </div>
                  <div className="date-month"> 
                    <div className="month-text"> {props.windowWidth < 1200? course.month.toUpperCase().slice(0,3) : course.month.toUpperCase()} </div>
                  </div>
                </div>
              </Row>
            ))}

          </Col>
        </Row>
      </Container>
  );
}

export default Calendar;