import React, { Component } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Drones extends Component {

  state= {
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
      <Nav/>
      <Container fluid className="drone-container">
        <Row className="mt-4">
          <Col lg="4" className="mb-md-5 mb-lg-0">
            <img className="drone-img mt-4" src="/images/flying-drone.jpg" alt="drone"></img>
          </Col>
          <Col lg="8" className="mt-3 mt-md-0">
            <Row>
              <Col md="12">
                <h3 className="page-title text-center mt-3 mb-4"> NUESTROS SERVICIOS DE DRONES </h3>
                <p className="page-text drone-text">En la aviación y en el espacio, un dron se refiere a una aeronave o nave espacial sin piloto. Es un "vehículo aéreo no tripulado" UAV ó también se le denomina RPA. </p>
                <p className="page-text drone-text">En México somos pioneros en brindar Cursos de Capacitación Piloto Remoto (Drone). Este curso está diseñado para enseñarte a volar tu Drone y prepararte a obtener la Licencia de piloto RPAS avalada por la Secretaría de Comunicaciones y Transporte (SCT) y la Agencia Federal de Aviación Civil  (AFAC).</p>
                <div className="drone-video-container">
                  <iframe className="drone-video" title="Drones" width="100%" height="100%" src="https://www.youtube.com/embed/76C1itoO5PU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5 mb-5 pr-5">
          <div className="contact-arrow text-right"><a className="page-title" href="/contacto"> Informes de inscripción ➔ </a></div>
        </Row>
      </Container>
      <Footer/>
      </div>
    );
  }
}

export default Drones;
