import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

class TestimonialCarousel extends Component {
  render() {
    return (
      <Carousel
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      infiniteLoop
      selectedItem={this.props.carouselPosition}
      >
        <div className="my-slide" style={{ padding: "30px"}}>
          <p className="page-text-white mt-4" style={{ fontSize: "1.8rem"}}> "Obtuvimos una experiencia profesional en todo momento"</p>
          <p className="page-text-white mt-5" style={{ fontSize: "1.2rem"}}> -Daniel Salas (Curso de piloto comercial)</p>
        </div>
        <div className="my-slide" style={{ padding: "30px"}}>
          <p className="page-text-white mt-4" style={{ fontSize: "1.8rem"}}> "Sus instructores tienen la mejor preparación, su simulador es excelente"</p>
          <p className="page-text-white mt-5" style={{ fontSize: "1.2rem"}}> -Álvaro López (Curso de Técnico en Mantenimiento)</p>
        </div>
        <div className="my-slide" style={{ padding: "30px"}}>
          <p className="page-text-white mt-4" style={{ fontSize: "1.8rem"}}> "Realizar prácticas en un avión real me ayudó a sentirme mejor preparada"</p>
          <p className="page-text-white mt-5" style={{ fontSize: "1.2rem"}}> -Laura Andinas (Curso de sobrecargo)</p>
        </div>
        <div className="my-slide" style={{ padding: "30px"}}>
          <p className="page-text-white mt-4" style={{ fontSize: "1.8rem"}}> "Tornado me ayudó a saber todo lo necesario para conseguir mi licencia RPAS"</p>
          <p className="page-text-white mt-5" style={{ fontSize: "1.2rem"}}> -Victor Suárez (Curso de piloto remoto)</p>
        </div>
      </Carousel>
    );
  }
};

export default TestimonialCarousel;