import React from "react";
import "./style.css";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Footer(props) {

  return (
    <div className="footer">
      <Container>
        <Row className="footer-content">
          <Col md="1"></Col>
          <Col className="contact-details" sm="8" md="6">
            <h4 className="page-title"> CONTACTO </h4>

            <p className="phones-desktop page-title mt-4"> 
              <i className="tornadoblue fa fa-phone"></i> <span className="ml-1"> (55)5639 8333 | (33)3615 2119 | (47)3732 7551 </span>
            </p>

            <p className="page-title mt-3"> 
            <i className="tornadoblue fa fa-envelope"></i> <span className="ml-1"> direccion@tornadoadiestramiento.com.mx </span>
            </p>
          </Col>
          <Col className="contact-details" sm="4" md="3">
            <div className="page-title" style={{ fontSize: "1.5rem"}}> 
              <a className="social-link" href="https://www.facebook.com/tornadommlo" target="_blank" rel="noopener noreferrer"><div className="social-icon-circle"><i className="social-icon tornadoblue fab fa-facebook-f"></i></div></a>
              <a className="social-link" href="https://www.instagram.com/tornadoadiestramiento/" target="_blank" rel="noopener noreferrer"><div className="social-icon-circle ml-3"><i className="social-icon tornadoblue fab fa-instagram"></i></div></a>
              <a className="social-link" href="https://www.youtube.com/@tornadoadiestramiento2436" target="_blank" rel="noopener noreferrer"><div className="social-icon-circle ml-3"><i className="social-icon tornadoblue fab fa-youtube"></i></div></a>
            </div>
            <p className="page-title mt-3 ml-sm-2"> 
              Mapa de Sitio
            </p>
            <p className="page-title mt-3 ml-sm-2"> 
              Aviso de Privacidad
            </p>

          </Col>
          <Col md="2"></Col>
        </Row>
      </Container>
      <div className="footer-bottom text-center mt-4">
          <p className="brand-rights page-text-white">
          © 2022 Tornado Asesoría en Transportación Aerea SA de CV
          </p>
        </div>
    </div>
  );
}

export default Footer;