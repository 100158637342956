import React from "react";
import "./style.css";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from 'react-bootstrap/Jumbotron'

function CareerInfo(props) {

  var foundInfo = null;
  var title = null;
  var imageUrl = null;
  var description = null;
  var subjects = ["Example array"];
  var midpoint = null;

  switch (props.name){
    case "sobrecargo":
      foundInfo = true;
      title = "Sobrecargo de Aviación"
      imageUrl = "/images/carrera-sobrecargo.jpg"
      description = "El sobrecargo de aviación (mal llamado Aeromoza o azafata), es parte de la tripulación de un avión, es la persona encargada de auxiliar al capitán de la aeronave para asegurar la buena operación de la cabina de pasajeros brindando de esta manera un traslado seguro del pasajero. \n Sus funciones específicas consisten en recibir, asistir y brindar seguridad a los pasajeros desde el momento en que ingresan a la aeronave hasta que llegan a su destino. \n Los Sobrecargos egresadas de Tornado, realizan prácticas en un DC9-32 totalmente equipado que cuenta con la certificación de entrenador de tripulaciones de cabina por parte de AFAC, lo que lo hace único en México.".split("\n")
      subjects = ["Introducción al medio Aeronáutico", "Reglamentación  aérea", "Aerodinámica", "Meteorología", "Requerimientos técnicos", "Relaciones humanas", "Servicios abordo", "Primeros auxilios", "Emergencias", "Práctica de ditching"]
      midpoint = subjects.length / 2
      break;
    case "oficial-de-operaciones":
      foundInfo = true;
      title = "Oficial de Operaciones Aeronáuticas"
      imageUrl = "/images/carrera-oficial.jpg"
      description = "La carrera de Oficial de Operaciones Aeronáuticas forma Personal Técnico Aeronáutico capacitado para encargarse del correcto despacho de una aeronave en tierra. \n Se encarga de la seguridad de la aeronave en tierra, del correcto abastecimiento de combustible, seleccionando las rutas aéreas más óptimas, es capaz de calcular los  rendimientos de las diferentes aeronaves además de realizar la correcta carga y balance de la aeronave, interpreta los informes meteorológicos de ruta y terminales, así como manejo de personal de rampa. \n Es decir es el complemento del piloto aviador en tierra.".split("\n")
      subjects = ["Introducción al medio Aeronáutico", "Reglamentación  aérea", "Aerodinámica", "Meteorología", "Aeronaves y motores", "Control de tránsito aéreo", "Navegación aérea", "Comunicaciones", "Operaciones aeroportuarias", "Reportes meteorológicos", "Rendimientos de aeronaves", "Prácticas profesionales"]
      midpoint = subjects.length / 2
      break;
    case "piloto-privado":
      foundInfo = true;
      title = "Piloto Privado de Ala Fija"
      imageUrl = "/images/carrera-pilotoprivado.jpg"
      description = "El Piloto privado de Ala Fija es capaz de aplicar sus conocimientos teóricos aprendido en las labores de control y operación de aeronaves ligeras. \n Durante la fase práctica, aprenderás en una técnica de vuelo excelente a bordo de una aeronave Cessna 150, realizando así tus primeras horas de vuelo en este fascinante mundo. \n Como piloto privado estas habilitado para operar aeronaves monomotores siguiendo las normas de seguridad de la industria aeronáutica, en vuelos de regla visual (VFR), siendo este tu primer paso para poder volar aeronaves de grandes dimensiones.".split("\n")
      subjects = ["Introducción al medio Aeronáutico", "Reglamentación  aérea", "Mecánica de vuelo (aerodinámica)", "Meteorología", "Aeronaves y motores", "Navegación avanzada", "Operaciones aeroportuarias", "Control de tránsito aéreo", "Comunicaciones", "40 Hr de vuelo"]
      midpoint = subjects.length / 2
      break;
    case "piloto-tpi":
      foundInfo = true;
      title = "Piloto de Transporte Público Ilimitado"
      imageUrl = "/images/carrera-pilototransporte.jpg"
      description = "Eres piloto Comercial con más de 1500 Horas y tu sueño es volar cualquier aeronave de ala fija al mando de ella, ven, toma el curso para piloto TPI. \n Este curso está diseñado para que conozcas aerodinámica transónica, derecho Aeronáutico, correcta interpretación de SOP, Call outs, funciones de piloto al mando, piloto monitoreando, piloto volando, esto te permitirá desarrollar tu función de capitán al mando cumpliendo con los más altos estándares de la aviación. \n Entérate de esto y más en Nuestro centro de capacitación.".split("\n")
      subjects = ["Derecho Aéreo", "Conocimiento de las Aeronaves de Ala Fija", "Rendimientos y Planificación del Vuelo", "Meteorología Aeronáutica", "Navegación Aérea", "Procedimientos Operacionales", "Principios de Vuelo", "Telecomunicaciones Aeronáuticas"]
      midpoint = subjects.length / 2
      break;
    case "observador-del-tiempo":
      foundInfo = true;
      title = "Observador del Tiempo"
      imageUrl = "/images/carrera-observador.jpg"
      description = "El Observador del Tiempo es el Personal Técnico Aeronáutico encargado de elaborar y difundir los reportes de las condiciones del tiempo en todos los Aeropuertos de la República Mexicana. \n También es capaz de encargarse de la elaboración y difusión de los pronósticos meteorológicos utilizados en la aviación, siendo de esta forma una parte fundamental de la industria aeronáutica, puesto que estos reportes condicionan el buen funcionamiento de los itinerarios de vuelo de las aeronaves tanto comerciales, privadas y ejecutivas. \n Incorpórate al fabuloso mundo de la aviación desde esta fascinante carrera.".split("\n")
      subjects = ["Introducción al medio aeronáutico", "Reglamentación  aérea", "Operaciones aeroportuarias", "Condición del cielo", "Visibilidad", "Fenómenos meteorológicos"]
      midpoint = subjects.length / 2
      break;
    case "tecnico-en-mantenimiento":
      foundInfo = true;
      title = "Técnico en Mantenimiento Clase 1 (Motores y Planeadores)"
      imageUrl = "/images/carrera-tecnico.jpg"
      description = "Sus labores consisten en realizar el mantenimiento de aeronaves de ala fija de todo tamaño. Gracias a sus actividades, las operaciones se realizan sin contratiempos y se minimizan los riesgos a los que se podrían ver expuestos los pasajeros, tripulantes o terceras partes en tierra. \n Este curso te preparará con conocimientos teóricos y prácticos para convertirte en uno de los mejores Técnicos en Mantenimiento de Aeronave en México.".split("\n")
      subjects = ["Introducción al medio aeronáutico", "Reglamentación  aérea", "Aerodinámica", "Aeronaves y motores", "Requerimientos técnicos", "Conocimiento de las Aeronaves de Ala Fija"]
      midpoint = subjects.length / 2
      break;
    default :
      foundInfo = false;
    break;
  }

  if (foundInfo) {
    return (
      <Container fluid className="career-container">
        <h3 className="page-title tornado-red text-center mt-5"> CARRERAS </h3>
        <Row>
          <Col md="4" className="mb-md-5 mb-lg-0">
            <h3 className="page-title text-center mt-4">{title}</h3>
            <img className="career-img mt-3 mb-4" src={imageUrl} alt="career"></img>
            <div className="text-center"><a href="/contacto"><button className="btn btn-tornado mb-md-5">¡Inscríbete ahora!</button></a></div>
          </Col>
          <Col md="8" className="mt-3 mt-md-0">
            <Row className="mt-4">
              {description.map(paragraph => (
                <p className="page-text career-text">{paragraph}</p>
              ))}
            </Row>
            <h5 className="page-title ml-4 mt-4 mb-3"> MATERIAS </h5>
            <Row noGutters className="mb-4">
              <Col md="6">
                <ol className="page-text subject-list">
                  {subjects.map((item, index) => {
                    return index <= midpoint - 1 ?
                    <li>{item}</li>
                    : null
                  })}
                </ol>
              </Col>
              <Col md="6">
                <ol start={midpoint+1} className="page-text subject-list">
                  {subjects.map((item, index) => {
                    return index >= midpoint ?
                    <li>{item}</li>
                    : null
                  })}
                </ol>
              </Col>
              
            </Row>
            <Row className="mb-5">
              <div className="return-arrow text-right pr-5"><a className="page-title" href="/cursos/carreras">Regresar a todas las carreras ➔ </a></div>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  else{
    return(
    <Container fluid>
      <Row>
        <Col size="md-12">
          <Jumbotron>
            <h1>404 Page Not Found</h1>
            <h1>
              <span role="img" aria-label="Face With Rolling Eyes Emoji">
                🙄
              </span>
            </h1>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
    );
  } 
}

export default CareerInfo;