import React, { Component } from "react";
import Nav from "../components/Nav";
import Footer from '../components/Footer'
import AboutHeader from '../components/AboutHeader'
import Certifications from '../components/Certifications'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class About extends Component {
  state = {
    
  };

  componentDidMount() {
    // this.loadUser();

    // console.log();
    // $('.toast').toast("hide")
  }

  render() {
    return (
      <div>
        <Nav/>
        <AboutHeader/>
        <Container className="about-container">
          <Row className="about-row">
            <Col md="4">
              <div className="about-section">
                <h2 className="page-title text-center pt-3"> MISIÓN </h2>
                <p className="mision-text page-text-jus about-content pt-3">
                  Ofrecer las mejores soluciones en Formación, Capacitación, Adiestramiento, Asesoría y 
                  Tramitación Aeronáutica por medio de los mejores profesionales de la aviación. Con esto logramos
                  que usted sólo se preocupe por disfrutar de su vuelo.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="about-section">
                <h2 className="page-title text-center pt-3"> VISIÓN </h2>
                <p className="page-text-jus about-content pt-3">
                  Ser la empresa de servicios educativos y de gestoría con el más alto nivel de rentabilidad, 
                  líder en el mercado, que garantice estabilidad, permanencia y calidad en la industria de la 
                  aviación Mexicana.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="about-section">
                <h2 className="page-title text-center pt-3"> VALORES </h2>
                <p className="page-text-jus about-content pt-3">
                  Tornado enaltece la actitud de servicio, la honestidad y la lealtad. Todos los profesionales 
                  que integran nuestra empresa trabajan en equipo, de manera comprometida y siempre mostrando respeto hacia los demás.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <h3 className="page-title-red mt-3"> NUESTRO COMPROMISO </h3>
        <Row className="commitment-row">
          <div className="commitment-list-container">
            <ul className="commitment-list mt-4">
              <li className="commitment page-text">
                Preparar Personal Técnico Aeronáutico con los conocimientos necesarios para el medio Aeronáutico en México.
              </li>
              <li className="commitment page-text">
                Cubrir las necesidades de capacitación y gestoría que las empresas Aéreas Mexicanas requieren.
              </li>
              <li className="commitment page-text">
                Ofrecer las mejores soluciones en Formación, Capacitación, Adiestramiento, Asesoría y Tramitación Aeronáutica.
              </li>
            </ul>
          </div>
        </Row>
        
        
        <Certifications/>
        <Container fluid className="sim-container">
          <Row>
            <Col xl="1"></Col>
            <Col lg="6" xl="6" className="emersim-details" id="emersim">
              <h2 className="page-title text-center">EMERSIM (CABIN CREW TRAINER)</h2>
              <p className="page-text sim-text text-center mt-3">
                Único  entrenador de tripulaciones en México reconocido por la AFAC. <br/> Emersim es ampliamente utilizado para nuestras
                prácticas de sobrecargo (en seco y húmedo) y prácticas de ditching y evacuación en tierra para la obtención del AOC.
                Si prefieres hacer tus prácticas en una alberca o vivir una experiencia previa que te puede salvar la vida, Emersim es ideal para ti. 
              </p>
            </Col>
            <Col lg="6" xl="4">
              <img className="emersim-img" src="/images/emersim.jpg" alt="emersim"></img>
            </Col>
            <Col xl="1"></Col>
          </Row>
          <Row>
            <div className="ditching-video-container">
              <iframe title="Ditching" width="100%" height="100%" src="https://www.youtube.com/embed/EWcSfDTL2Cg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Row>
        </Container>
        <Footer/>
      </div>
    );
  }
}

export default About;
