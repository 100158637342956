import React from "react";
import "./style.css";
import GoogleMap from "../GoogleMap";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function ContactInfo(props) {
  var foundInfo = null;
  var name = null;
  var mapName = null;
  var mapId = null;
  var mapsLink = null;
  var address = null;
  var phone = null;
  var email = null;
  var latitude = null;
  var longitude = null;

  switch (props.location){
    case "CDMX":
      foundInfo = true;
      mapId = "cdmx-map"
      name = "Base CDMX"
      mapName = "Tornado CDMX"
      address = "Av. Insurgentes Sur 299, Hipódromo, 06100 Ciudad de México, CDMX, México"
      phone = "Oficina: (55) 5639 8333 - Whatsapp: (55) 6168 7332"
      email = "atencionalcliente_cdmx@tornadoadiestramiento.com.mx"
      latitude = 19.4147273
      longitude = -99.1660791
      mapsLink = "https://goo.gl/maps/ta7k8g1CSo3KxTty6"
      break;
    case "Guadalajara":
      foundInfo = true;
      mapId = "gdl-map"
      name = "Sub-base Guadalajara"
      mapName = "Tornado Guadalajara"
      address = "Av. Nicolás Copérnico 4294, Arboledas, 45070 Zapopan, Jalisco"
      phone = "Oficina: (33) 3615 2119 - Whatsapp: (33) 2533 0165"
      email = "tornadogdl@tornadoadiestramiento.com.mx"
      latitude = 20.632593
      longitude = -103.424828
      mapsLink = "https://goo.gl/maps/vMrm4dKji9BzshpXA"
      break;
    case "Toluca":
      foundInfo = true;
      mapId = "toluca-map"
      name = "Sub-base Toluca"
      mapName = "Tornado Toluca"
      address = "Miguel Alemán 1663, Reforma, Delegación Santa María Totoltepec, 52100 San Mateo Atenco, Mex."
      phone = "Celular: (47) 3732 7551"
      email = "tornado.tlc.adiestramiento@gmail.com"
      latitude = 19.2988314
      longitude = -99.559040
      mapsLink = "https://goo.gl/maps/pUpYDn2XUfbfCvC49"
      break;
    default :
      foundInfo = false;
    break;
  }

	return (
		<Container className="contact-container">
      <Row className="card-row mt-lg-4 mb-sm-4">
        <Col lg="1"></Col>
        <Col className="contact-content pl-4" sm="12" lg="5">
          <h4 className="page-title-white mt-2">{name}</h4>
          <Row className="mt-3">
            <Col className="contact-icon-col" md="1"><i className="tornadowhite fas fa-map-marked-alt"></i></Col>
            <Col md="10">
            <p className="page-text-white">
              {address}
            </p>
            </Col>
          </Row>
          <Row>
            <Col className="contact-icon-col" md="1"><i className="tornadowhite fa fa-phone"></i></Col>
            <Col md="10">
            <p className="page-title-white">
              {phone}
            </p>
            </Col>
          </Row>
          <Row>
            <Col className="contact-icon-col" md="1"><i className="tornadowhite fa fa-envelope"></i></Col>
            <Col md="10">
            <p className="page-title-white">
              <a className='mailtoLink' href={`mailto:${email}`}>{email}</a>
            </p>
            </Col>
          </Row>
        </Col>
        <Col className="map-column" sm="12" lg="6">
          <div className="map-wrapper">
            <GoogleMap googleMapId={mapId} link={mapsLink} name={mapName} lat={latitude} lon={longitude}/>
          </div>
        </Col>
      </Row>
    </Container>
	);
}

export default ContactInfo;