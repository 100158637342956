import React, { Component } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import CareerInfo from "../components/CareerInfo";

class CareerDetail extends Component {
  state = {
    windowWidth: 1200,
    windowHeight: 800,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.setState({ 
      careerName: this.props.match.params.careername
    });
  }

  componentWillUnmount() {
    clearInterval(this.galleryInterval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight }, () => {console.log(this.state)});
  }

  render() {
    return (
      <div>
      <Nav/>
      <CareerInfo name={this.state.careerName}/>
      <Footer/>
      </div>
    );
  }
}

export default CareerDetail;