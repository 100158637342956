import React, { Component } from "react";
import "./style.css";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function InfoHeader(props) {

  if (props.page === "asesoria"){
    return (
      <div className="info-header">
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <img className="asesoria-img-mobile" src="/images/asesoria.jpg" alt="asesoria"></img>

          <Row noGutters>
            <Col className="content-column pl-3 pr-3 pr-lg-0 pb-3 pb-lg-0" lg="8">
              <h3 className="info-title page-title-white mb-4"> ASESORÍA Y GESTORÍA </h3>
              {/* <div className="width-85 mb-3"><li className="page-text-white">Asesoría y elaboración de estudios técnicos necesarios para la planeación y la creación de nuevas empresas aéreas tales como, taxis aéreos, aerolíneas comerciales y de aviación general.</li></div>
              <div className="width-85 mb-3"><li className="page-text-white">Auditorías internas relacionadas con las operaciones de las empresas (pre verificación de la AFAC). </li></div>
              <div className="width-80 mb-3"><li className="page-text-white">Elaboración de todos los manuales que se presentan ante la AFAC (MGO, MGM, MSA, MSPAII, MMYPTA, manual de despacho, manual de rampa, manejo de combustible, manejo de mercancías peligrosas, etc.) </li></div>
              <div className="width-80 mb-3"><li className="page-text-white">Trámites para la obtención o revalidación de los certificados de aeronavegabilidad de las aeronaves, en territorio nacional o en el extranjero.</li></div>
              <div className="width-75 mb-3"><li className="page-text-white">Verificación  pre-compra de aeronaves. Análisis de fallas de los sistemas de las aeronaves.  </li></div>
              <div className="width-75 mb-3"><li className="page-text-white">Tramitación aeronáutica ante la AFAC. Trámites para cambio de propietario. </li></div>
              <div className="width-75 mb-3"><li className="page-text-white">Permiso de internación al país de aeronaves y sus componentes.</li></div> */}
              <h5 className="page-title-white">Certificados, permisos y autorizaciones</h5>
              <div className="width-85 mb-3"><p className="page-text-white">Nuestra empresa provee del servicio de consultoría y gestoría en solicitudes o trámites referentes a la obtención de certificados, permisos y autorizaciones necesarias para poder llevar a cabo las operaciones principales de la empresa. </p></div>
              <h5 className="page-title-white">Ingeniería de operaciones</h5>
              <div className="width-80 mb-3"><p className="page-text-white">Proporcionamos el servicio de consultoría y gestoría en la rama de Ingeniería de operaciones ante la AFAC, con el fin de facilitarle conocimiento o manejo de todo el proceso necesario para tener un control de sus requerimientos en las operaciones de sus aeronaves o su empresa del sector aéreo. </p></div>
              <h5 className="page-title-white">Publicaciones técnicas</h5>
              <div className="width-75 mb-3"><p className="page-text-white">También ofrecemos el servicio de elaboración y enmiendas (manuales) de publicaciones técnicas para llevar a cabo las operaciones de las aeronaves y procedimientos del personal de la empresa cliente. </p></div>
            </Col>
            <Col className="remainder-column" lg="4"></Col>
          </Row>
  
          <img className="asesoria-img" src="/images/asesoria.jpg" alt="asesoria"></img>
        </Container>
      </div>
    );
  }
  if (props.page === "sms"){
    return (
      <div className="info-header">
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <img className="sms-img-mobile" src="/images/sms-plane.jpg" alt="sms"></img>
  
          <Row noGutters>
            <Col className="remainder-column" lg="4"></Col>
            <Col className="content-column-right pl-3 pl-lg-0 pr-3 pb-3 pb-lg-0" lg="8">
              <h3 className="info-title-right page-title-white mb-4"> ¿QUÉ ES SMS? </h3>
              <div className="right-align width-85 mb-3"><li className="page-text-white">De acuerdo a la Organización de Aviación Civil Internacional (OACI), SMS (Safety Management System, por sus siglas en inglés) se refiere a un sistema diseñado y empleado para gestionar la seguridad operacional en la aviación.</li></div>
              <div className="right-align width-80 mb-3"><li className="page-text-white">Este sistema tiene el propósito de mejorar continuamente la seguridad operacional mediante la identificación de peligros, análisis de datos y la evaluación continua de los riesgos operacionales. </li></div>
              <div className="right-align width-75 mb-3"><li className="page-text-white">Los estándares y prácticas recomendadas (SARPS) de la OACI dictan la implementación de un plan de SMS para un amplío rango de entidades en la aviación. Estos incluyen desde operadores y fabricantes de aeronaves hasta organizaciones dedicadas a la capacitación. </li></div>
              <div className="right-align width-70 mb-3"><li className="page-text-white">La Secretaría de Comunicaciones y Transportes ha establecido un proceso de certificación en SMS para entidades de la industria aeronáutica en México, el cual consta de cuatro fases de evaluación. </li></div>
              <div className="right-align width-65 mb-3"><li className="page-text-white">Los instructores de Tornado han sido capacitados y certificados en el diseño e implementación de SMS, por lo cual contamos con la experiencia necesaria para ofrecer diversos servicios relacionados a este sistema.  </li></div>
            </Col>
          </Row>
  
          <img className="sms-img" src="/images/sms-plane.jpg" alt="sms"></img>
        </Container>
      </div>
    );
  }
}

export default InfoHeader;