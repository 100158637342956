import React from "react";
import "./style.css";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function AboutHeader(props) {
  return (
    <div>
      <div className="header-container">
        <img className="about-img" src="images/emersim.jpg" id="gallery-1" alt="testimonial"></img>
        <div className="about-div">
          <div className="introduction-container">
            <h3 className="page-title-white-centered mt-5"> QUIÉNES SOMOS </h3>
            <p className="about-text page-text-white text-center"> TORNADO ASESORÍA EN TRANSPORTACIÓN AÉREA SA DE CV <br/><br/> 
            Escuela de aviación en México dedicada a la capacitación, asesoría y tramitación aeronáutica. <br/><br/>
            Surgida en el año 1997, fundada por su actual Director General Ing. Roberto Chávez González. </p>
          </div>
        </div>
      </div>
      {/* <div className="about-cover"></div> */}
    </div>
  );
}

export default AboutHeader;