import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Welcome from "./pages/Welcome";
import About from "./pages/About";
import Courses from "./pages/Courses";
import CareerDetail from "./pages/CareerDetail";
import Consulting from "./pages/Consulting";
import Drones from "./pages/Drones";
import SMS from "./pages/SMS";
import Contact from "./pages/Contact";
import NoMatch from "./pages/NoMatch";
import './App.css';



function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/nosotros" component={About} />
          <Route exact path="/cursos/:type" component={Courses} />
          <Route exact path="/cursos/carreras/:careername" component={CareerDetail} />
          <Route exact path="/asesoria" component={Consulting} />
          <Route exact path="/drones" component={Drones} />
          <Route exact path="/sms" component={SMS} />
          <Route exact path="/contacto" component={Contact} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;