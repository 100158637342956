import React, { Component } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InfoHeader from "../components/InfoHeader";

class SMS extends Component {

  state = {
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <Nav />
        <InfoHeader page="sms" />
        <Container className="services-container">
          <h3 className="sms-services-title page-title text-center"> NUESTROS SERVICIOS SMS </h3>
          <Row noGutters>
            <Col md="6" lg="3">
              <div className="sms-card">
                <div className="text-div">
                  <p className="sms-services-text page-title-white">
                    ELABORACIÓN DEL MANUAL (MSMS)
                  </p> 
                </div>
                <div className="description-overlay">
                  <div className="text-div">
                    <p className="sms-description-text page-title-white">
                    Elaboración del manual SMS y Plan de Implementación, llevando el control de su aprobación ante la Agencia Federal de Aviación Civil.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6" lg="3">
              <div className="sms-card">
                <div className="text-div">
                  <p className="sms-services-text page-title-white">
                    IMPLEMENTACIÓN DE FASES II, III Y IV
                  </p> 
                </div>
                <div className="description-overlay">
                  <div className="text-div">
                    <p className="sms-description-text page-title-white">
                      Asesoramiento mediante un análisis de las condiciones actuales del sistema por medio de herramientas administrativas.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6" lg="3">
              <div className="sms-card">
                <div className="text-div">
                  <p className="sms-services-text page-title-white">
                    AUDITORÍAS SMS
                  </p> 
                </div>
                <div className="description-overlay">
                  <div className="text-div">
                    <p className="sms-description-text page-title-white">
                      Seguimiento y propuestas de cumplimiento de las fases 2, 3 y 4, así como un control en la presentación de documentación ante la Agencia Federal de Aviación Civil.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="6" lg="3">
              <div className="sms-card">
                <div className="text-div">
                  <p className="sms-services-text page-title-white">
                    CAPACITACIÓN Y ADIESTRAMIENTO
                  </p> 
                </div>
                <div className="description-overlay">
                  <div className="text-div">
                    <p className="sms-description-text page-title-white">
                      Capacitación para personal de la empresa cliente y para el responsable de implementar el sistema.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-5 mt-md-3">
            <a className="contact-arrow" href="/contacto"><h3 className="page-title">SOLICITA UNA COTIZACIÓN</h3></a>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default SMS;