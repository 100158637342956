import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ContactInfo from "../components/ContactInfo";

class Contact extends Component {
  state = {
    windowWidth: 1200,
    windowHeight: 800,
    googleMapsReady: false
  };

  componentWillMount() {
    this.loadGoogleMaps(() => {
      this.setState({ googleMapsReady: true }, () => {
        console.log("updated state")
        console.log(this.state)
      });
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  loadGoogleMaps = (callback) => {
    const existingScript = document.getElementById('maps-api-script');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC2oZFY4ROPk-XlGoXRKugDbrmRqyVpXLk';
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.id = 'maps-api-script';
      document.body.appendChild(script);
      console.log("script created")
      script.onload = () => {
        console.log("script loaded")
        callback();
      };
    }

    if (existingScript) callback();
  };

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight }, () => { console.log(this.state) });
  }

  render() {
    return (
      <div>
        <Nav />
        <h3 className="contact-title page-title text-center">¿NECESITAS CAPACITACIÓN? CONTÁCTANOS</h3>
        <div className="contact-wrapper">
          {this.state.googleMapsReady ? <ContactInfo location="CDMX" /> : ''}
          {this.state.googleMapsReady ? <ContactInfo location="Guadalajara" /> : ''}
          {this.state.googleMapsReady ? <ContactInfo location="Toluca" /> : ''}
          <Container className="contact-container">
            <div id="email-contact" className="contact-content">
              <h4 className="page-title-white">Contacto General</h4>
              <p className="page-text-white">Informes: <a className='mailtoLink' href="mailto:direccion@tornadoadiestramiento.com.mx">direccion@tornadoadiestramiento.com.mx</a></p>
              <p className="page-text-white">Servicio Social Convenio vigente con IPN: <a className='mailtoLink' href="mailto:capacitacion@tornadoadiestramiento.com.mx">capacitacion@tornadoadiestramiento.com.mx</a></p>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;