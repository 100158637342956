import React, { Component, createRef } from 'react'

class GoogleMap extends Component {
  googleMap = null;
  marker = null;
  mapName = this.props.name
  mapId = this.props.googleMapId
  mapsLink = this.props.link
  latitude = this.props.lat
  longitude = this.props.lon

  componentDidMount() {
    this.createGoogleMap(this.createMarker)
  }

  createGoogleMap = (callback) => {
    this.googleMap = new window.google.maps.Map(document.getElementById(this.mapId), {
      zoom: 16,
      center: {
        lat: this.latitude,
        lng: this.longitude,
      }
    })
    callback()
  }

  createMarker = () => {
    this.marker = new window.google.maps.Marker({
      position: { lat: this.latitude, lng: this.longitude },
      map: this.googleMap,
    })
    var infoWindow = new window.google.maps.InfoWindow({
      content: `<a href=${this.mapsLink}>${this.mapName}</a>`
    });
    infoWindow.open(this.googleMap, this.marker);
  }

  render() {
    return (
      <div
        className="google-map"
        id={this.mapId}
        style={{ width: '100%', height: '100%' }}
      />
    )
  }
}

export default GoogleMap;
